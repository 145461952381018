import React from 'react'
import { connect } from 'react-redux'
import DocumentMeta from 'react-document-meta';
import { withRouter, Redirect } from 'react-router-dom'

// import { increaseViewCounts } from '../firebase/firebase.utils'

// import { FacebookShareButton, FacebookShareCount } from 'react-share'
// import { FacebookProvider, ShareButton } from 'react-facebook';

import { CategoryConstant } from '../redux/category/category.reducer'

import { selectAllAds } from '../redux/ads/ads.selector'
import { fetchAdsStart } from '../redux/ads/ads.action'

// const SinglePage = ({ location }) => {

class SinglePage extends React.Component {
    componentDidMount() {
        const { fetchAdsStart } = this.props
        fetchAdsStart()
    }

    render() {
        if (this.props.location.state === undefined) {
            return <Redirect to={`/singleNewsShare/${this.props.match.params.collectionId}`} />
        }
        const feed = this.props.location.state.feed
        if (feed === undefined) {
            return <Redirect to={`/singleNewsShare/${this.props.match.params.collectionId}`} />
        }
        const ads = this.props.allAds

        const meta = {
            title: feed.heading,
            description: feed.heading,
            canonical: feed.linkUrl,
            property: "og:image",
            // image: feed.imageUrl,
            image: 'https://via.placeholder.com/150/149540',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'news,dewas,rashtriya navachar'
                },
                property: "og:image",
                // image: feed.imageUrl,
                image: 'https://via.placeholder.com/150/149540',
            }
        };
        var whatsappShare = `https://api.whatsapp.com/send?text=*${feed.heading}*    \n *दैनिक राष्ट्रीय नवाचार*  \n https://rashtriyanavachar.com/singleNewsShare/${feed.id} \n ${feed.youTubeUrl}`;
        whatsappShare = whatsappShare.replace("#", " ")
        var d = new Date(feed.crd);
        var date = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()}`
        var videoUrl = feed.youTubeUrl
        if (feed.youTubeUrl !== '') {
            videoUrl = feed.youTubeUrl.replace("https://youtu.be", "")
            videoUrl = "https://www.youtube.com/embed" + videoUrl
        }
        return (
            <DocumentMeta {...meta}>
                <section className="section wb">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-wrapper">
                                    <div className="blog-title-area">
                                        <span className="color-aqua"><a title="">{CategoryConstant(feed.category)}</a></span>
                                        <h3>{feed.heading}</h3>

                                        <div className="blog-meta big-meta">
                                            <small><a>{date}</a></small>
                                            <small><a>{feed.reporter}</a></small>
                                            {/* <small><a href="#" title=""><i className="fa fa-eye"></i> {feed.views}</a></small> */}
                                        </div>
                                        <div className="post-sharing">

                                            <ul className="list-inline">
                                                <li><a href={whatsappShare} className="fb-button btn btn-whatsapp">
                                                    <i className="fa fa-whatsapp"></i>
                                                    <span className="down-mobile">Share on Whatsapp</span>
                                                </a></li>
                                                <p></p>
                                                {
                                                    videoUrl !== '' ?
                                                        <div>
                                                            <li><a href={feed.youTubeUrl} className="btn btn-youtube" target="blank">
                                                                <i className="fa fa-youtube"></i>
                                                            &nbsp;
                                                    <span className="down-mobile"> Watch </span>
                                                            </a>
                                                            </li>
                                                        </div>
                                                        : <a></a>
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="single-post-media">
                                        <img src={feed.imageUrl} alt="" className="img-fluid" />
                                    </div>
                                    {
                                        videoUrl !== '' ?
                                            <div className="single-post-media">
                                                <iframe title={feed.heading}
                                                    src={videoUrl}
                                                    allowfullscreen>
                                                </iframe>
                                            </div>
                                            : <div></div>
                                    }
                                    <div className="blog-content">
                                        <div className="pp">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: feed.content
                                                }}></div>
                                        </div>

                                    </div>
                                    <hr className="invis1" />


                                    {/* <div className="custombox clearfix">
                                <h4 className="small-title">You may also like</h4>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="blog-box">
                                            <div className="post-media">
                                                <a href="single.html" title="">
                                                    <img src="upload/menu_06.jpg" alt="" className="img-fluid" />
                                                    <div className="hovereffect">
                                                        <span className=""></span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="blog-meta">
                                                <h4><a href="single.html" title="">We are guests of ABC Design Studio</a></h4>
                                                <small><a href="blog-category-01.html" title="">Trends</a></small>
                                                <small><a href="blog-category-01.html" title="">21 July, 2017</a></small>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div className="col-lg-6">
                                        <div className="blog-box">
                                            <div className="post-media">
                                                <a href="single.html" title="">
                                                    <img src="upload/menu_07.jpg" alt="" className="img-fluid" />
                                                    <div className="hovereffect">
                                                        <span className=""></span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="blog-meta">
                                                <h4><a href="single.html" title="">Nostalgia at work with family</a></h4>
                                                <small><a href="blog-category-01.html" title="">News</a></small>
                                                <small><a href="blog-category-01.html" title="">20 July, 2017</a></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                    <hr className="invis1" />
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                <div className="sidebar">
                                    <div className="widget">
                                        <h2 className="widget-title">Advertising</h2>
                                        {
                                            ads ?
                                                ads.map(ad =>
                                                    <div className="banner-spot clearfix">
                                                        <div className="banner-img">
                                                            <img src={ad.imageUrl} alt="" className="img-fluid" />
                                                        </div>
                                                        <br />
                                                    </div>
                                                ) :
                                                <h5></h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </DocumentMeta>
        )
    }

    // const { feed } = location.state

    // return (

    // )
};

const mapStateToProps = (state) => ({
    allAds: selectAllAds(state)
})

export const mapDispatchToProp = dispatch => ({
    fetchAdsStart: (id) => dispatch(fetchAdsStart(id))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProp)(SinglePage))