import { createSelector } from 'reselect'

const selectArticles = state => state.article


export const selectAllArticles = createSelector(
    [selectArticles],
    article => article.articles
)

export const selectArticleByCategory = category => createSelector(
    [selectAllArticles],
    articles => articles[category]
)

export const selectArticleById = createSelector(
    [selectArticles],
    articles => articles.singleArticle
)

export const selectIsNewsFetching = createSelector(
    [selectArticles],
    articles => articles.isFetching
)

export const selectLastFetchedTime = createSelector(
    [selectArticles],
    articles => articles.isLodedAt
)
