import { createSelector } from 'reselect'

const selectEPaper = state => state.ePaper

export const selectAllEPaper = createSelector(
    [selectEPaper],
    ePaper => ePaper.ePaper
)

export const selectEPaperCover = category => {
    return (createSelector(
        [selectAllEPaper],
        ePaper => ePaper['epaperCover']
    ))
}
export const selectEPaperPages = category => {
    return (createSelector(
        [selectAllEPaper],
        ePaper => ePaper['page']
    ))
}

export const selectIsNewsFetching = createSelector(
    [selectAllEPaper],
    ePaper => ePaper.isFetching
) 
