
export const CategoryConstant = (type) => {
    switch (type) {
        case 'dewasKhel': return 'Dewas Khel'
        case 'dewasRajneeti': return 'Dewas Rajneeti'
        case 'dewasCrime': return 'Dewas Crime'
        case 'dewasEvent': return 'Dewas Event'
        case 'dewasSiksha': return 'Dewas Skisha'
        case 'dewasPrashasan': return 'Dewas Prashasan'
        case 'indore': return 'Indore'
        case 'ujjain': return 'Ujjain'
        case 'madhyaPradesh': return 'Madhya Pradesh'
        case 'national': return 'National'
        case 'interNational': return 'International'
        case 'editorsCorner': return 'सम्पादक की और से'
        case 'readersCorner': return 'Readers Corner'
        case 'ladiesCorner': return 'Ladies Corner'
        default: return "News"
    }

}
export const INITIAL_STATE = {
    dewasNewsCategory: [
        {
            title: 'Dewas Event',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'dws1',
            category: 'dewasEvent',
            linkUrl: '/singleCategory/dewasEvent'
        },
        {
            title: 'Dewas Crime',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'dws2',
            category: 'dewasCrime',
            linkUrl: '/singleCategory/dewasCrime'
        },
        {
            title: 'Dewas Prashasan',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'dws3',
            category: 'dewasPrashasan',
            linkUrl: '/singleCategory/dewasPrashasan'
        },
        {
            title: 'Dewas Rajneeti',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'dws4',
            category: 'dewasRajneeti',
            linkUrl: '/singleCategory/dewasRajneeti'
        },
        {
            title: 'Dewas Khel',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'dws5',
            category: 'dewasKhel',
            linkUrl: '/singleCategory/dewasKhel'
        },
        {
            title: 'Dewas Siksha',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'dws7',
            category: 'dewasSiksha',
            linkUrl: '/singleCategory/dewasSiksha'
        }
    ],
    newsCategory: [
        {
            title: 'Ujjain',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'ujjain',
            linkUrl: '/singleCategory/ujjain'
        },
        {
            title: 'Indore',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'indore',
            linkUrl: '/singleCategory/indore'
        },
        {
            title: 'Madhya Pradesh',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'madhyaPradesh',
            linkUrl: '/singleCategory/madhyaPradesh'
        },
        {
            title: 'National',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 'national',
            linkUrl: '/singleCategory/national'
        },
        {
            title: 'International',
            imageUrl: 'https://i.ibb.co/0jqHpnp/sneakers.png',
            id: 'interNational',
            linkUrl: '/singleCategory/interNational'
        }
    ],

    articleCategory: [
        {
            title: 'Readers Corner',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 6,
            category: 'readersCorner',
            linkUrl: '/article/readersCorner'
        },
        {
            title: 'Ladies Corner',
            imageUrl: 'https://i.ibb.co/0jqHpnp/sneakers.png',
            id: 7,
            category: 'ladiesCorner',
            linkUrl: '/article/ladiesCorner'
        },
        {
            title: 'Editor\'s Corner',
            imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
            id: 8,
            category: 'editorsCorner',
            linkUrl: '/article/editorsCorner'
        }
    ]
};

const NewsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default: return state
    }
}

export default NewsReducer