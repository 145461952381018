import React from 'react'
import { Link } from 'react-router-dom'

export const SmallNewsItem = ({ feed }) => {
    var d = new Date(feed.crd);
    var date = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()}`
    return (
        <Link
            to={{
                pathname: `${feed.linkUrl}`,
                state: {
                    feed: feed
                }
            }}>
            <span href="single.html" className="list-group-item list-group-item-action flex-column align-items-start">
                <div className="w-100 justify-content-between">
                    <h5 className="mb-1">{feed.heading}</h5>
                    <small>{date}</small>
                </div>
            </span>
        </Link>
    )
}

export default SmallNewsItem