import React from 'react'
import { connect } from 'react-redux'

import { selectCategoryNews } from '../../../redux/news/news.selector'
import { fetchNewsStart } from '../../../redux/news/news.action'

import {HomeBreakingNewsItem} from './home-breaking-news-item.component'

class HomeBreakingNews extends React.Component {

    componentDidMount() {
        const { fetchNewsStart } = this.props
        fetchNewsStart();
    }
    render() {
        const { breakingNews } = this.props
        return (
            <section className="section first-section">
                <div className="container-fluid">
                    <div className="masonry-blog clearfix">
                        {
                            breakingNews.filter((item, idx) => idx < 6).map(news=>
                                <HomeBreakingNewsItem id={news.id} feed={news}/>
                                )
                        }
                    </div>
                </div>
            </section>
        )
    }
}


export const mapStateToProps = (state) => ({
    breakingNews: selectCategoryNews('topBigNews')(state)
})

export const mapDispatchToProp = dispatch => ({
    fetchNewsStart: () => dispatch(fetchNewsStart())
})

export default connect(mapStateToProps, mapDispatchToProp)(HomeBreakingNews)