import React from 'react'
import DocumentMeta from 'react-document-meta';

import { withRouter } from 'react-router-dom'

import { CategoryConstant } from '../redux/category/category.reducer'

const SinglePage = ({ location }) => {

    console.log(location)
    const { feed } = location.state
    const meta = {
        title: feed.heading,
        description: feed.heading,
        canonical: feed.linkUrl,
        property: "og:image",
        image: feed.imageUrl,
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'news,dewas,rashtriya navachar,article'
            },
            property: "og:image",
            image: feed.imageUrl,
        }
    };
    
    var whatsappShare = `https://api.whatsapp.com/send?text=*${feed.heading}*    \n *दैनिक राष्ट्रीय नवाचार*  \n https://rashtriyanavachar.com/singleArticleShare/${feed.id}`;
    whatsappShare = whatsappShare.replace("#", " ")

    var d = new Date(feed.crd);
    var date = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()}`
    return (
        <DocumentMeta {...meta}>
            <section className="section wb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-wrapper">
                                <div className="blog-title-area">
                                    <span className="color-aqua"><a>{CategoryConstant(feed.category)}</a></span>
                                    <h3>{feed.heading}</h3>

                                    <div className="blog-meta big-meta">
                                        <small><a>{date}</a></small>
                                        {feed.source.length !== 0 ? <small>by{feed.source}</small> : ''}
                                        {/* <small><a href="#" title=""><i className="fa fa-eye"></i> {feed.views}</a></small> */}
                                    </div>
                                    <div className="post-sharing">
                                        <ul className="list-inline">
                                            <li><a href={whatsappShare} className="fb-button btn btn-whatsapp">
                                                <i className="fa fa-whatsapp"></i>
                                                <span className="down-mobile">Share on Whatsapp</span>
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="single-post-media">
                                    <img src={feed.imageUrl} alt="" className="img-fluid" />
                                </div>

                                <div className="blog-content">
                                    <div className="pp">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: feed.content
                                            }}></div>
                                    </div>

                                </div>

                                <hr className="invis1" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            {/* <div className="sidebar">
                                <SmallNewsList category={feed.category} />
                                <div className="widget">
                                    <h2 className="widget-title">Advertising</h2>
                                    <div className="banner-spot clearfix">
                                        <div className="banner-img">
                                            <img src={feed.imageUrl} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </DocumentMeta>
    )
};

export default withRouter(SinglePage)