import { takeEvery, takeLatest, call, put, all } from 'redux-saga/effects'
import { firestore, convertDataToObject, 
    convertDataToObjectWithoutImage, convertDataToObjectBreakingNews, 
    convertSingleDataToObject } from '../../firebase/firebase.utils'

import { NewsActionTypes } from './news.types'

import { fetchNewsSuccess, fetchNewsSuccessByCategory, fetchNewsSuccessById, fetchNewsFailure } from './news.action'
import { INITIAL_STATE } from '../category/category.reducer'


export function* fetchBreakingNewsStartAsync() {
    try {
        const collectionRef = firestore.collection('news')
            .where("active", "==", true)
            .where("type", "==", "Big")
            .orderBy("crd", "desc")
            .limit(6)
        const snapshot = yield collectionRef.get()
        const collectionsMap = yield call(convertDataToObjectBreakingNews, snapshot)
        yield put(fetchNewsSuccess(collectionsMap))
    }
    catch (error) {
        yield put(fetchNewsFailure(error.message))
    }
}


export function* fetchNewsByCategoryStartAsync(action) {
    try {
        const collectionRefCat = firestore.collection('news')
            .where("active", "==", true)
            .where("category", "==", action.category)
            .orderBy("crd", "desc")
            .limit(action.limit)

        const collectionRef = firestore.collection('news')
            .where("active", "==", true)
            .orderBy("crd", "desc")
            .limit(action.limit)

        var snapshot;
        if (action.category.length === 0) {
            snapshot = yield collectionRef.get()
        } else {
            snapshot = yield collectionRefCat.get()
        }
        if (action.isWithImage) {
            if (action.category.length === 0) {
                yield all(INITIAL_STATE.dewasNewsCategory.map(cat =>
                    call(convertToMap, snapshot, cat.category)
                ))
            } else {
                const collectionsMap = yield call(convertDataToObject, snapshot, action.category)
                yield put(fetchNewsSuccessByCategory(collectionsMap, action.category))
            }
        }
        else {
            const collectionsMap = call(convertDataToObjectWithoutImage, snapshot)
            yield put(fetchNewsSuccessByCategory(collectionsMap, action.category))
        }

    }
    catch (error) {
        yield put(fetchNewsFailure(error.message))
    }
}

function* convertToMap(snapshot, category) {
    const collectionsMap = yield call(convertDataToObject, snapshot, category)
    yield put(fetchNewsSuccessByCategory(collectionsMap, category))
}

export function* fetchSingleNewsByIdAsync(action) {
    try {
        const collectionRef = firestore.collection('news')
            .doc(action.id)
        const snapshot = yield collectionRef.get()
        const news = yield call(convertSingleDataToObject, snapshot)
        yield put(fetchNewsSuccessById(news))
    }
    catch (error) {
        yield put(fetchNewsFailure(error.message))
    }
}

export function* fetchNewsByIdStart() {
    yield takeLatest(
        NewsActionTypes.FETCH_NEWS_BY_ID_START,
        fetchSingleNewsByIdAsync
    )
}

export function* fetchBreakingNewsStart() {
    yield takeLatest(
        NewsActionTypes.FETCH_NEWS_START,
        fetchBreakingNewsStartAsync
    )
}

export function* fetchNewsByCategoryStart() {
    yield takeEvery(
        NewsActionTypes.FETCH_NEWS_BY_CATEGORY_START,
        fetchNewsByCategoryStartAsync
    )
}

export function* newsSagas() {
    yield all([
        call(fetchNewsByCategoryStart),
        call(fetchBreakingNewsStart),
        call(fetchNewsByIdStart)
    ])
}