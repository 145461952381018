import React from 'react'

import { connect } from 'react-redux'
import { selectCategoryNews } from '../../redux/news/news.selector'

import CategoryNewsItem from './category-news-item.component'

const CategoryNewsList = ({ categoryNews }) => {
    return (
        <div class="row">
            {
                categoryNews ?
                    categoryNews.filter((item, idx) => idx < 5).map(feed =>
                        <CategoryNewsItem key={feed.id} feed={feed} />
                    ) :
                    <h5>No data found</h5>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    categoryNews: selectCategoryNews(ownProps.category)(state)
})

export default connect(mapStateToProps)(CategoryNewsList)