import React from 'react'
import { Link } from 'react-router-dom'

export const MenuHeaderNewsItem = ({ feed }) => (
    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div className="blog-box">
            <Link
                to={{
                    pathname: `${feed.linkUrl}`,
                    state: {
                        feed: feed
                    }
                }}>
                <div className="post-media">
                    <img src={feed.imageUrl} alt="" className="img-fluid" />
                    <div className="hovereffect">
                    </div>
                    <span className="menucat"></span>
                </div>
                <div className="blog-meta">
                    <h4><a>{feed.heading}</a></h4>
                </div>
            </Link>
        </div>
    </div>
)

export default MenuHeaderNewsItem