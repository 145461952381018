import React from 'react'

import { withRouter } from 'react-router-dom'

import { fetchNewsByCategoryStart } from '../redux/news/news.action'

import SmallNewsList from '../components/single-news/small-news-list.component'
import CategoryNewsList from '../components/single-category/category-news-list.component'
import { CategoryConstant } from '../redux/category/category.reducer'
import { connect } from 'react-redux'

class SingleCagetoryPage extends React.Component {

    componentDidMount() {
        const { fetchNewsByCategoryStart } = this.props
        fetchNewsByCategoryStart(this.props.match.params.categoryId, true);
    }

    render() {
        const category = this.props.match.params.categoryId
        return (
            <section className="section wb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <div class="page-wrapper">
                                <h2>{CategoryConstant(category)}</h2>
                                <div class="blog-grid-system">
                                    <div class="row">
                                        <CategoryNewsList category={category} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="sidebar">
                                <SmallNewsList category={category} />
                                <div className="widget">
                                    <h2 className="widget-title">Advertising</h2>
                                    <div className="banner-spot clearfix">
                                        <div className="banner-img">
                                            {/* <img src={feed.imageUrl} alt="" className="img-fluid" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};

const mapDispatchToProp = dispatch => ({
    fetchNewsByCategoryStart: (category) => dispatch(fetchNewsByCategoryStart(category, true, 20))
})

export default withRouter(connect(null, mapDispatchToProp)(SingleCagetoryPage))