import { takeLatest, call, put } from 'redux-saga/effects'

import { firestore, convertEPaperDataToObject, convertEPaperPagesDataToObject } from '../../firebase/firebase.utils'
import { fetchEPaperSuccess, fetchEPaperFailure, fetchEPaperPagesSuccess } from './epaper.action'
import { EPaperActionTypes } from './epaper.types'



export function* fetchEPaperStartAsync() {
    try {
        const collectionRef = firestore.collection('epaper')
            .where("active", "==", true)
            .orderBy("crd", "desc")
            .limit(8)
        const snapshot = yield collectionRef.get()
        const collectionMap = yield call(convertEPaperDataToObject, snapshot)
        yield put(fetchEPaperSuccess(collectionMap))
    } catch (error) {
        yield put(fetchEPaperFailure(error.message))
    }
}

export function* fetchEPaperPageStartAsync(action) {
    try {
        const collectionRef = firestore.collection('epaper')
            .doc(action.docId)
            .collection("pages")
            .orderBy("page")
        const snapshot = yield collectionRef.get()
        const collectionMap = yield call(convertEPaperPagesDataToObject, snapshot)
        yield put(fetchEPaperPagesSuccess(collectionMap))
    }
    catch (error) {
        yield put(fetchEPaperFailure(error.message))
    }
}


export function* fetchEPaperStart() {
    yield takeLatest(
        EPaperActionTypes.FETCH_EPAPER_START,
        fetchEPaperStartAsync
    )
}


export function* fetchEPaperPageStart() {
    yield takeLatest(
        EPaperActionTypes.FETCH_EPAPER_SINGLE_START,
        fetchEPaperPageStartAsync
    )
}