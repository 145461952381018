import React from 'react'
import { Link } from 'react-router-dom'
import { CategoryConstant } from '../../../redux/category/category.reducer'
export const HomeBreakingNewsItem = ({ feed }) => {
    var d = new Date(feed.crd);
    var date = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()}`
    return (
        <div className="left-side">
            <div className="masonry-box post-media">
                <img src={feed.imageUrl} alt="" className="img-fluid" />
                <div className="shadoweffect">
                    <div className="shadow-desc">
                        <div className="blog-meta">
                            <Link
                                to={{
                                    pathname: `singleCategory/${feed.category}`,
                                    state: {
                                        feed: feed
                                    }
                                }}>
                                <span className="bg-aqua">{CategoryConstant(feed.category)}</span>
                            </Link>
                            <Link
                                to={{
                                    pathname: `${feed.linkUrl}`,
                                    state: {
                                        feed: feed
                                    }
                                }}>
                                <h4><a>{feed.heading}</a></h4>
                            </Link>
                            <small><a>{date}</a></small>
                            <small><a>by {feed.reporter}</a></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBreakingNewsItem