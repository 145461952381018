
import React from 'react'
import Logo from '../../assets/images/logo.png'

export const Footer = () => (
    <footer className="footer">
        <div className="container">
            <hr className="invis" />
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className="widget">
                        <div className="footer-text text-center">
                            <a href=""><img src={Logo} alt="logo" /></a>
                            <p>सकारात्मक सोच, व्यापक दृष्टिकोण के साथ शिक्षा, स्वास्थ्य, महिला-बच्चों सहित सामाजिक मुद्दों से सरोकार</p>
                            <div className="social">
                                <a href="https://www.facebook.com/dharmendrapiplodiyanavachar" target="blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Facebook"><i className="fa fa-facebook"></i></a>
                                <a href="https://twitter.com/dharmendrapipl" target="blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Twitter"><i className="fa fa-twitter"></i></a>
                                <a href="https://www.instagram.com/dharmendra.piplodiya/" target="blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram"><i className="fa fa-instagram"></i></a>
                                <a href="https://t.me/joinchat/P4XqRxycZwt_za04oMwtqg " target="blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Telegram"><i className="fa fa-telegram"></i></a>
                                <a href="https://www.youtube.com/channel/UCPUn5wFbOlgShuA6mftMMMA" target="blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Youtbe"><i className="fa fa-youtube"></i></a>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="copyright"> दैनिक राष्ट्रीय नवाचार. ©2020 All rights reserved</div>
                    <div className="copyright"> <b>Contact Us:</b></div>
                    <div className="copyright">
                        7489316866<br></br>
                        rashtriyanavachar@gmail.com<br></br>
                        121 Champakuti Bhopal Square, BNP Road<br></br>
                        Dewas 455001, MP India
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer