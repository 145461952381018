import { EPaperActionTypes } from './epaper.types'

export const fetchEPaperStart = () => ({
    type: EPaperActionTypes.FETCH_EPAPER_START
})

export const fetchEPaperSuccess = collectionMap => ({
    type: EPaperActionTypes.FETCH_EPAPER_SUCCESS,
    payload: collectionMap
})

export const fetchEPaperPagesSuccess = (collectionMap, docId) => ({
    type: EPaperActionTypes.FETCH_EPAPER_SINGLE_SUCCESS,
    payload: collectionMap,
    docId: docId
})

export const fetchEPaperFailure = errorMessage => ({
    type: EPaperActionTypes.FETCH_EPAPER_FAILURE,
    payload: errorMessage
})