import React from 'react'
import { Link } from 'react-router-dom'

export const CategoryNewsItem = ({ feed }) => {
    var d = new Date(feed.crd);
    var date = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()}`
    return (
        <div>
            <Link
                to={{
                    pathname: `${feed.linkUrl}`,
                    state: {
                        feed: feed
                    }
                }}>
                <div className="blog-box row">
                    <div className="col-md-4">
                        <div className="post-media">
                            <img src={feed.imageUrl} alt='item' className="img-fluid" />
                            <div className="hovereffect"></div>
                        </div>
                    </div>
                    <div className="blog-meta big-meta col-md-8">
                        <h4><a>{feed.heading}</a></h4>
                        <small>{date}</small>
                        {feed.reporter.length !== 0 ? <small>by {feed.reporter}</small> : ''}
                    </div>
                </div>
            </Link>
            <hr className="invis" />
        </div>
    )
}

export default CategoryNewsItem