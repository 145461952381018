import React from 'react'

import { connect } from 'react-redux'
import { selectCategoryNews } from '../../redux/news/news.selector'

import MenuHeaderNewsItem from './menu-header-news-item.component'

const MenuHeaderNewsList = ({ categoryNews, category }) => {
    return (
        <div className="row">
            {
                categoryNews ?
                    categoryNews.filter((item, idx) => idx < 4).map(feed =>
                        <MenuHeaderNewsItem key={feed.id} feed={feed} />
                    ) :
                    <h5>No data found</h5>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    categoryNews: selectCategoryNews(ownProps.category.category)(state)
})

export default connect(mapStateToProps)(MenuHeaderNewsList)