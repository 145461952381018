import { combineReducers } from 'redux'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import CategoryReducer from './category/category.reducer'
import NewsReducer from './news/news.reducer'
import ArticleReducer from './article/article.reducer'
import EPaperReducer from './epaper/epaper.reducer'
import AdsReducer from './ads/ads.reducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['cart']
}

const rootReducer = combineReducers({
    news: NewsReducer,
    category: CategoryReducer,
    article: ArticleReducer,
    ePaper: EPaperReducer,
    ads: AdsReducer
});

export default persistReducer(persistConfig, rootReducer)
// export default rootReducer