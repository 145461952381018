import React from 'react';
import { Switch, Route } from 'react-router-dom'

import { SocailHeader } from './components/social-header/social-header.components'
import Header from './components/header/header.component'
import Footer from './components/footer/footer.component'
import MenuHeader from './components/menu-header/menu-header.component'
import HomePage from './pages/homopage.component'
import SinglePage from './pages/single-news-page.component'
import SingleShareNewsPage from './pages/single-share-news-page.component'
import SingleShareArticlePage from './pages/single-share-article-page.component'
import SingleArticlePage from './pages/single-article-page.component'
import SingleCagetoryPage from './pages/single-category-page.component'
import WithSpinner from './components/with-spinner/with-spinner.component'
import EPaperPage from './pages/epaper-page.component'

import './App.css';

function App() {
  const SinglePageWithSpinner = WithSpinner(SinglePage)
  const SingleShareNewsPageWithSpinner = WithSpinner(SingleShareNewsPage)
  const SingleShareArticlePageWithSpinner = WithSpinner(SingleShareArticlePage)
  const SingleArticlePageWithSpinner = WithSpinner(SingleArticlePage)
  const SingleCategoryPageWithSpinner = WithSpinner(SingleCagetoryPage)
  const EPaperPageWithSpinner = WithSpinner(EPaperPage)
  return (
    <div className="App">
      <div id="wrapper">
        {/* <SocailHeader /> */}
        <Header />
        <MenuHeader />
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route path='/singleNews/:collectionId' render={(props) => <SinglePageWithSpinner isLoading={false}{...props} />} />
          <Route path='/singleNewsShare/:collectionId' render={(props) => <SingleShareNewsPageWithSpinner isLoading={false}{...props} />} />
          <Route path='/singleArticleShare/:collectionId' render={(props) => <SingleShareArticlePageWithSpinner isLoading={false}{...props} />} />
          <Route path='/singleArticle/:collectionId' render={(props) => <SingleArticlePageWithSpinner isLoading={false}{...props} />} />
          <Route exact path='/singleCategory/:categoryId' render={(props) => <SingleCategoryPageWithSpinner isLoading={false}{...props} />} />
          <Route exact path='/epaper' render={(props) => <EPaperPageWithSpinner isLoading={false}{...props} />} />
        </Switch>
        <Footer />
      </div>
    </div>
  );
}

export default App
