import { AdsActionTypes } from './ads.types'

const defaultImageUrl = 'https://firebasestorage.googleapis.com/v0/b/rashtriyanavachar-2020.appspot.com/o/defaultImage%2Fdefault_image_placeholder.png?alt=media&token=63298486-e47f-4975-9a0d-d7d9e37d2cf3'
// const defaultImageUrl = 'https://i.ibb.co/px2tCc3/jackets.png'

const demoData = [
    {
        heading: 'Loading ...',
        imageUrl: defaultImageUrl,
        id: 'ads1',
        views: 1
    },
    {
        heading: 'Loading ...',
        imageUrl: defaultImageUrl,
        id: 'ads2',
        views: 2
    },
    {
        heading: 'Loading News...',
        imageUrl: defaultImageUrl,
        id: 'ads3',
        views: 3
    }
]

const INITIAL_STATE = {
    isFetching: false,
    errorMessage: undefined,
    singleAds: undefined,
    isLodedAt: 0,
    ads: JSON.parse(localStorage.getItem("ads")) || demoData
};

const AdsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AdsActionTypes.FETCH_ADS_BY_CATEGORY_START:
            return {
                ...state,
                isFetching: true
            }
        case AdsActionTypes.FETCH_ADS_SUCCESS:
            {
                localStorage.setItem(action.category, JSON.stringify(action.payload) || demoData)
                return {
                    ...state,
                    isFetching: false,
                    errorMessage: null,
                    isLodedAt: Date.now(),
                    ads: action.payload
                }
            }
        case AdsActionTypes.FETCH_ADS_BY_ID_SUCCESS:
            {
                return {
                    ...state,
                    isFetching: false,
                    errorMessage: null,
                    singleAds: action.payload
                }
            }
        case AdsActionTypes.FETCH_ADS_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        default: return state
    }
}

export default AdsReducer