import React from 'react'

import EPaperCoverItem from './epaper-cover-item.component'

const EPaperList = ({ ePaper }) => {
    return (
        <div class="row">
            {
                //  [1,2,3,4,5,6,7,8,9,10].map(data=>
                //     <EPaperCoverItem />
                //     )
                ePaper ?
                    ePaper.filter((item, idx) => idx < 5).map(feed =>
                        <EPaperCoverItem key={feed.id} feed={feed} />
                    ) :
                    <h5>No data found</h5>
            }
        </div>
    )
}

export default EPaperList