export const NewsActionTypes = {
    FETCH_NEWS_START: 'FETCH_NEWS_START',
    FETCH_NEWS_BY_CATEGORY_START: 'FETCH_NEWS_BY_CATEGORY_START',
    FETCH_NEWS_BY_ID_START: 'FETCH_NEWS_BY_ID_START',
    FETCH_NEWS_BY_CATEGORY_NI_START: 'FETCH_NEWS_BY_CATEGORY_NI_START',
    FETCH_NEWS_SUCCESS:'FETCH_NEWS_SUCCESS',
    FETCH_NEWS_BY_CATEGORY_SUCCESS:'FETCH_NEWS_BY_CATEGORY_SUCCESS',
    FETCH_NEWS_BY_CATEGORY_NI_SUCCESS:'FETCH_NEWS_BY_CATEGORY_NI_SUCCESS',
    FETCH_NEWS_BY_ID_SUCCESS:'FETCH_NEWS_BY_ID_SUCCESS',
    FETCH_NEWS_FAILURE:'FETCH_NEWS_FAILURE'
}