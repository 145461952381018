import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { fetchArticleByCategoryStart } from '../../../redux/article/article.action'
import { selectLastFetchedTime } from '../../../redux/article/article.selector'

import HomeArticleList from './home-article-list.component'


class HomeArticleContainer extends React.Component {
    componentDidMount() {
        const { fetchArticleByCategoryStart, selectLastFetchedTime, category } = this.props
        const fetchDifference = Date.now() - selectLastFetchedTime;

        if (fetchDifference > (1 * 60 * 1000)) {
            fetchArticleByCategoryStart(category.category);
        }
    }

    render() {
        const {category} = this.props
        return (
            <div>
                {
                    <HomeArticleList key="1" category={category} />
                }
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    selectLastFetchedTime: selectLastFetchedTime
})

export const mapDispatchToProp = dispatch => ({
    fetchArticleByCategoryStart: (category) => dispatch(fetchArticleByCategoryStart(category, 150))
})

export default connect(mapStateToProps, mapDispatchToProp)(HomeArticleContainer)