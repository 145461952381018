import { NewsActionTypes } from './news.types'

export const fetchNewsStart = () => ({
    type: NewsActionTypes.FETCH_NEWS_START
})

export const fetchNewsByCategoryStart = (category, isWithImage, limit) => ({
    type: NewsActionTypes.FETCH_NEWS_BY_CATEGORY_START,
    isWithImage: isWithImage,
    category: category,
    limit: limit
})

export const fetchNewsByIDStart = (id) => ({
    type: NewsActionTypes.FETCH_NEWS_BY_ID_START,
    id: id
})

export const fetchNewsSuccess = collectionMap => ({
    type: NewsActionTypes.FETCH_NEWS_SUCCESS,
    payload: collectionMap
})

export const fetchNewsSuccessByCategory = (collectionMap, category) => ({
    type: NewsActionTypes.FETCH_NEWS_BY_CATEGORY_SUCCESS,
    payload: collectionMap,
    category: category
})

export const fetchNewsSuccessById = (collectionMap) => ({
    type: NewsActionTypes.FETCH_NEWS_BY_ID_SUCCESS,
    payload: collectionMap
})

export const fetchNewsFailure = errorMessage => ({
    type: NewsActionTypes.FETCH_NEWS_FAILURE,
    payload: errorMessage
})

export const updateNewsCollections = (collectionMap) => ({
    types: NewsActionTypes.UPDATE_COLLETIONS,
    payload: collectionMap
})