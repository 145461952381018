import { ArticleActionTypes } from './article.types'

export const fetchArticleStart = () => ({
    type: ArticleActionTypes.FETCH_ARTICLE_START
})

export const fetchArticleByCategoryStart = (category, limit) => ({
    type: ArticleActionTypes.FETCH_ARTICLE_BY_CATEGORY_START,
    category: category,
    limit: limit
})

export const fetchArticleByIDStart = (id) => ({
    type: ArticleActionTypes.FETCH_ARTICLE_BY_ID_START,
    id: id
})

export const fetchArticleSuccess = collectionMap => ({
    type: ArticleActionTypes.FETCH_ARTICLE_SUCCESS,
    payload: collectionMap
})

export const fetchArticleSuccessByCategory = (collectionMap, category) => ({
    type: ArticleActionTypes.FETCH_ARTICLE_BY_CATEGORY_SUCCESS,
    payload: collectionMap,
    category: category
})

export const fetchArticleSuccessById = (collectionMap) => ({
    type: ArticleActionTypes.FETCH_ARTICLE_BY_ID_SUCCESS,
    payload: collectionMap
})

export const fetchArticleFailure = errorMessage => ({
    type: ArticleActionTypes.FETCH_ARTICLE_FAILURE,
    payload: errorMessage
})

export const updateNewsCollections = (collectionMap) => ({
    types: ArticleActionTypes.UPDATEARTICLELETIONS,
    payload: collectionMap
})