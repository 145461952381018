import { createSelector } from 'reselect'

const selectAds = state => state.ads


export const selectAllAds = createSelector(
    [selectAds],
    ads => ads.ads
)


export const selectLastFetchedTime = createSelector(
    [selectAds],
    adss => adss.isLodedAt
)
