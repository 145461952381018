import React from 'react'

import HomeCategoryContainer from '../components/home-components/home-news-category/home-category-container/home-category-container.component'
import HomeArticleContainer from '../components/home-components/home-article/home-article-container.component'
import HomeBreakingNews from '../components/home-components/home-breaking-news/home-breaking-news.component'
import SmallNewsList from '../components/single-news/small-news-list.component'

const HomePage = () => (
    <div>
        <HomeBreakingNews />
        <section className="section">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-lg-2">
                        <HomeArticleContainer category={{ title: "Ladies Corner", category: "ladiesCorner" }} />
                    </div>
                    <div className="col-lg-6">
                        <HomeCategoryContainer />
                    </div>
                    <div className="col-lg-2">
                        <HomeArticleContainer category={{ title: "Readers Corner", category: "readersCorner" }} />
                        <HomeArticleContainer category={{ title: "सम्पादक की और से", category: "editorsCorner" }} />
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                        <div className="sidebar">
                            <SmallNewsList category='dewasEvent' />
                            <div className="widget">
                                {/* <h2 className="widget-title">Advertising</h2> */}
                                <div className="banner-spot clearfix">
                                    <div className="banner-img">
                                        {/* <img src={feed.imageUrl} alt="" className="img-fluid" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
);

export default HomePage;