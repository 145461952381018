import { NewsActionTypes } from "./news.types";
const defaultImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/rashtriyanavachar-2020.appspot.com/o/defaultImage%2Fdefault_image_placeholder.png?alt=media&token=63298486-e47f-4975-9a0d-d7d9e37d2cf3";
// const defaultImageUrl = 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'

const demoNews = [
  {
    heading: "Loading News...",
    imageUrl: defaultImageUrl,
    id: "dewasCrime1",
    source: "",
    views: 0,
    linkUrl: "single/",
    reporter: "",
  },
  {
    heading: "Loading News...",
    imageUrl: defaultImageUrl,
    id: "dewasCrime2",
    source: "",
    views: 0,
    linkUrl: "/singleNews/6",
    reporter: "",
  },
  {
    heading: "Loading News...",
    imageUrl: defaultImageUrl,
    id: "dewasCrime3",
    source: "",
    views: 0,
    linkUrl: "/singleNews/6",
    reporter: "",
  },
];
const INITIAL_STATE = {
  isFetching: false,
  errorMessage: undefined,
  isLodedAt: 0,
  singleNews: undefined,
  news: {
    topBigNews: JSON.parse(localStorage.getItem("topBigNews") || "[]"),
    dewas: [
      {
        heading: "Dewas News 1",
        imageUrl: "https://i.ibb.co/px2tCc3/jackets.png",
        id: "dewas1",
        source: "",
        views: 0,
        linkUrl: "single/",
      },
      {
        heading: "Dewas  News 2",
        imageUrl: "https://i.ibb.co/GCCdy8t/womens.png",
        id: "dewas2",
        source: "",
        views: 0,
        linkUrl: "/singleNews/6",
      },
      {
        heading: "Dewas  News 3",
        imageUrl: "https://i.ibb.co/cvpntL1/hats.png",
        id: "dewas3",
        source: "",
        views: 0,
        linkUrl: "/singleNews/6",
      },
    ],
    dewasRajneeti:
      JSON.parse(localStorage.getItem("dewasRajneeti")) || demoNews,
    dewasEvent: JSON.parse(localStorage.getItem("dewasEvent")) || demoNews,
    dewasCrime: JSON.parse(localStorage.getItem("dewasCrime")) || demoNews,
    dewasPrashasan:
      JSON.parse(localStorage.getItem("dewasPrashasan")) || demoNews,
    dewasSiksha: JSON.parse(localStorage.getItem("dewasSiksha")) || demoNews,
    dewasKhel: JSON.parse(localStorage.getItem("dewasKhel")) || demoNews,
    ujjain: JSON.parse(localStorage.getItem("ujjain")) || demoNews,
  },
};

const NewsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NewsActionTypes.FETCH_NEWS_START:
      return {
        ...state,
        isFetching: true,
      };
    case NewsActionTypes.FETCH_NEWS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        news: {
          ...state.news,
          topBigNews: action.payload,
        },
      };
    }
    case NewsActionTypes.FETCH_NEWS_BY_CATEGORY_SUCCESS: {
      localStorage.setItem(
        action.category,
        JSON.stringify(action.payload) || demoNews
      );
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        isLodedAt: Date.now(),
        news: {
          ...state.news,
          [action.category]: action.payload || demoNews,
        },
      };
    }
    case NewsActionTypes.FETCH_NEWS_BY_ID_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        singleNews: action.payload,
      };
    }
    case NewsActionTypes.FETCH_NEWS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default NewsReducer;
