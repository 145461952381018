import { AdsActionTypes } from './ads.types'

export const fetchAdsStart = () => ({
    type: AdsActionTypes.FETCH_ADS_START
})

export const fetchAdsSuccess = collectionMap => ({
    type: AdsActionTypes.FETCH_ADS_SUCCESS,
    payload: collectionMap
})

export const fetchAdsSuccessById = (collectionMap) => ({
    type: AdsActionTypes.FETCH_ADS_BY_ID_SUCCESS,
    payload: collectionMap
})

export const fetchAdsFailure = errorMessage => ({
    type: AdsActionTypes.FETCH_ADS_FAILURE,
    payload: errorMessage
})