
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics';

// var config = {
//     apiKey: "AIzaSyChCFzfhiRQdFYcKG724G1Agw_eLGS1U9M",
//     authDomain: "pratikvishwakarma-1434.firebaseapp.com",
//     databaseURL: "https://pratikvishwakarma-1434.firebaseio.com",
//     projectId: "pratikvishwakarma-1434",
//     storageBucket: "pratikvishwakarma-1434.appspot.com",
//     messagingSenderId: "854806447094",
//     appId: "1:854806447094:web:da98bb6f187b62331cfdd7"
// };

var config = {
    apiKey: "AIzaSyAQDplRGjOEcbZNVVxD7iMIjpZZ1Pe4I_A",
    authDomain: "rashtriyanavachar-2020.firebaseapp.com",
    databaseURL: "https://rashtriyanavachar-2020.firebaseio.com",
    projectId: "rashtriyanavachar-2020",
    storageBucket: "rashtriyanavachar-2020.appspot.com",
    messagingSenderId: "88700314721",
    appId: "1:88700314721:web:1b92adbf1371d435263620",
    measurementId: "G-HWWFB44MCR"
};
firebase.initializeApp(config);
firebase.analytics();


export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`)

    const snapShot = userRef.get();
    if (!snapShot.exist) {
        const { displayName, email, uid } = userAuth;
        const createdAt = new Date().setMilliseconds(0);

        try {
            await userRef.set({
                displayName, email, id: uid, createdAt, ...additionalData
            })
        } catch (error) {
            console.log(`error creating user `, error.message);
        }
    }
    return userRef;
}

export const addCollectionAndItems = async (collectionName, objectsToAdd) => {
    const collectionRef = firestore.collection(collectionName);

    const batch = firestore.batch()
    objectsToAdd.forEach(obj => {
        const newDocRef = collectionRef.doc()
        batch.set(newDocRef, obj);
    })

    return await batch.commit()
}

// to convert big breaking news into map with image
export const convertDataToObjectBreakingNews = (collections) => {
    return collections.docs.filter((item, idx) => item.data().imageUrl !== '').map(doc => {
        const { id, ...data } = doc.data()
        console.log(doc.data())
        return {
            linkUrl: `/singleNews/${id}`,
            id,
            ...data
        };
    })
}


// to convert category news into map with image
export const convertDataToObject = (collections, category) => {
    return collections.docs.filter((item, idx) => item.data().imageUrl !== '' && item.data().category === category).map(doc => {
        const { id, ...data } = doc.data()
        return {
            linkUrl: `/singleNews/${id}`,
            id,
            ...data
        };
    })
}

// to convert single news image
export const convertSingleDataToObject = (news) => {
    const { id, ...data } = news.data()
    return {
        linkUrl: `/singleNews/${id}`,
        id,
        ...data
    };
}

// to convert category news into map with image
export const convertArticleToObject = (collections, category) => {
    return collections.docs.filter((item, idx) => item.data().imageUrl !== '' && item.data().category === category).map(doc => {
        const { id, ...data } = doc.data()
        return {
            linkUrl: `/singleArticle/${id}`,
            id,
            ...data
        };
    })
}

// to convert single article image
export const convertSingleArticleDataToObject = (news) => {
    const { id, ...data } = news.data()
    console.log(data)
    return {
        linkUrl: `/singleArticle/${id}`,
        id,
        ...data
    };
}


// to convert category news into map without image
export const convertDataToObjectWithoutImage = collections => {
    return collections.docs.filter((item, idx) => item.data().imageUrl === '').map(doc => {
        const { id, ...data } = doc.data()
        return {
            linkUrl: `/singleNews/${id}`,
            ...data
        };
    })
}

// to convert epaper data into map with image
export const convertEPaperDataToObject = collections => {
    return collections.docs.map(doc => {
        const { id, ...data } = doc.data()
        return {
            linkUrl: `/singleNews/${id}`,
            ...data
        };
    })
}


export const convertEPaperPagesDataToObject = collections => {
    return collections.docs.map(doc => {
        return doc.data()
    })
}

// to increase views count of the news single news image
export const increaseViewCounts = (news) => {
    firestore.collection("news").doc(news.id).update({ views: news.views + 1 });
}


// to increase views count of the single article single
export const increaseArticleViewCounts = (news) => {
    firestore.collection("article").doc(news.id).update({ views: news.views + 1 });
}


// to convert category news into map with image
export const convertAdsToObject = (collections) => {
    return collections.docs.map(doc => {
        const { id, ...data } = doc.data()
        return {
            // linkUrl: `/singleArticle/${id}`,
            id,
            ...data
        };
    })
}


// const provider = new firebase.auth.GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });

// export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;

