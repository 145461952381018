
import React from 'react'
import Logo  from '../../assets/images/logo.png'

export const Header = () => (
    <div>
        <div className="header-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="logo">
                            <a href="">
                                <img src={Logo} alt="logo" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Header