import React from 'react'
import { Link } from 'react-router-dom'

export const CategoryNewsItem = ({ feed }) => (
    <div class="col-md-6">
        <div class="blog-box">
            <Link
                to={{
                    pathname: `${feed.linkUrl}`,
                    state: {
                        feed: feed
                    }
                }}>
                <div class="post-media">
                    <img src={feed.imageUrl} alt="" class="img-fluid" />
                    <div class="hovereffect">
                        <span></span>
                    </div>
                </div>

                <div class="blog-meta big-meta">
                    <h5><a>{feed.heading}</a></h5>
                    {feed.reporter.length !== 0 ? <small>by {feed.reporter}</small> : ''}
                </div>
            </Link>
        </div>
    </div>
)

export default CategoryNewsItem