import React from 'react'
import { Link } from 'react-router-dom'

export const EPaperCoverItem = ({ feed }) => (

    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div className="blog-box">
            <div className="post-media">
                <a href="single.html" title="">
                    <img src="upload/blog_01.jpg" alt="" className="img-fluid" />
                    <div className="hovereffect">
                        <span></span>
                    </div>
                </a>
            </div>
            <div className="blog-meta">
                <h4><a href="single.html" title="">{feed.name}</a></h4>
            </div>
        </div>
    </div>


)

export default EPaperCoverItem