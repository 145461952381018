import React from 'react'
import { Link } from 'react-router-dom'

import HomeCategoryListContainer from './home-category-list.container'


const HomeCategoryList = ({ category, newsCategory }) => {
    return (
        <div className="blog-list clearfix">
            <div className="section-title">
                <Link
                    to={{
                        pathname: `singleCategory/${category.category}`,
                        state: {
                            feed: category
                        }
                    }}>
                    <h3 className="color-green"><a href="blog-category-01.html" title="">{category.title}</a></h3>
                </Link>
                {
                    <HomeCategoryListContainer key={category.id} category={category} />
                }
            </div>
        </div>
    )
}

export default HomeCategoryList

