import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { selectDewasNewsCategory } from '../../../../redux/category/category.selector'
import { fetchNewsByCategoryStart } from '../../../../redux/news/news.action'
import { selectLastFetchedTime } from '../../../../redux/news/news.selector'
import CategoryList from '../home-category-list/home-category-list.component'


class HomeCategoryContainer extends React.Component {
    componentDidMount() {
        const { fetchNewsByCategoryStart, selectLastFetchedTime } = this.props
        const fetchDifference = Date.now() - selectLastFetchedTime;

        console.log(`Fetched difference : ${fetchDifference}`)
        if (fetchDifference > (1 * 60 * 1000)) {
            fetchNewsByCategoryStart("");
        }
    }

    render() {
        const { dewasNewsCategory } = this.props
        return (
            <div>
                {
                    dewasNewsCategory.map(category => (
                        <CategoryList key={category.id} category={category} />
                    ))
                }
            </div>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    dewasNewsCategory: selectDewasNewsCategory,
    selectLastFetchedTime: selectLastFetchedTime
})

export const mapDispatchToProp = dispatch => ({
    fetchNewsByCategoryStart: (category) => dispatch(fetchNewsByCategoryStart(category, true, 150))
})


export default connect(mapStateToProps, mapDispatchToProp)(HomeCategoryContainer)