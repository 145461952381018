import { createSelector } from 'reselect'

const selectNews = state => state.news

export const selectAllNews = createSelector(
    [selectNews],
    allNews => allNews.news
)

export const selectCategoryNews = category => {
    return (createSelector(
        [selectAllNews],
        news => news[category]
    ))
}

export const selectNewsById = createSelector (
    [selectNews],
    news => news.singleNews
)

export const selectIsNewsFetching = createSelector(
    [selectNews],
    news => news.isFetching
) 

export const selectLastFetchedTime = createSelector(
    [selectNews],
    allNews => allNews.isLodedAt        
)
