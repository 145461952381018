import React from 'react'
import { connect } from 'react-redux'

import { fetchNewsByCategoryStart } from '../../../../redux/news/news.action'
import { selectCategoryNews } from '../../../../redux/news/news.selector'

import { CategoryNewsItem } from '../../../category-news-item/category-news-item.component'


class HomeCategoryListContainer extends React.Component {
    componentDidMount() {
        // const { fetchNewsByCategoryStart } = this.props
        // fetchNewsByCategoryStart(this.props.category.category);
    }

    render() {
        const { newsCategory } = this.props
        return (
            <div className="blog-list clearfix">
                <div className="section-title">
                    {
                        newsCategory ?
                            newsCategory.filter((item, idx) => idx < 4).map(feed =>
                                <CategoryNewsItem key={feed.id} feed={feed} />
                            ) :
                            <h5>No data found</h5>
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    newsCategory: selectCategoryNews(ownProps.category.category)(state)
})

// export const mapDispatchToProp = dispatch => ({
//     fetchNewsByCategoryStart: (category) => dispatch(fetchNewsByCategoryStart(category, true, 4))
// })

export default connect(mapStateToProps, null)(HomeCategoryListContainer)  