import { createSelector } from 'reselect'

const selectCategory = state => state.category

export const selectDewasNewsCategory = createSelector(
    [selectCategory],
    dewasCategory => dewasCategory.dewasNewsCategory
)

export const selectNewsCategory = createSelector(
    [selectCategory],
    newsCategory => newsCategory.newsCategory
)

export const selectArticleCategory = createSelector(
    [selectCategory],
    articleCategory => articleCategory.articleCategory
)
