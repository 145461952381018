import React from 'react'
import { Link } from 'react-router-dom'

export const ArticleItem = ({ feed }) => {
    var d = new Date(feed.crd);
    var date = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()}`
    console.log(feed);
    return (
        <div>
            <div className="blog-box">
                <div className="post-media">
                    <Link
                        to={{
                            pathname: `${feed.linkUrl}`,
                            state: {
                                feed: feed
                            }
                        }}>
                        <a>
                            <img src={feed.imageUrl} alt='item' className="img-fluid" />
                            <div className="hovereffect" />
                        </a>
                    </Link>

                </div>
                <div className="blog-meta">
                    <Link
                        to={{
                            pathname: `${feed.linkUrl}`,
                            state: {
                                feed: feed
                            }
                        }}>
                      {feed.source.length !== 0 ? (
                            <h5><a>{feed.heading} <small>({feed.source})</small>
                            </a></h5>) : <h5><a>{feed.heading}</a></h5>}

                    </Link>
                </div>

            </div>
        </div>
    )
}

export default ArticleItem