import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router-dom'


import { selectNewsCategory, selectDewasNewsCategory, history, match } from '../../redux/category/category.selector'


import MenuHeaderNewsList from './menu-header-news-list.component'

const openCategory = (evt, catName) => {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with className="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }

    // Get all elements with className="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the link that opened the tab
    document.getElementById(catName).style.display = "block";
    evt.currentTarget.className += " active";
}

const MenuHeader = ({ newsCategory, dewasNewsCategory }) => (
    <header className="header">
        <div className="container">
            <nav className="navbar navbar-inverse navbar-toggleable-md">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#cloapediamenu" aria-controls="cloapediamenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-md-center" id="cloapediamenu">
                    <ul className="navbar-nav">
                        <Link to='/'>
                            <li className="nav-item">
                                <span className="nav-link color-pink-hover">Home</span>
                            </li>
                        </Link>
                        <li className="nav-item dropdown has-submenu menu-large hidden-md-down hidden-sm-down hidden-xs-down">
                            <span className="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dewas</span>
                            <ul className="dropdown-menu megamenu" aria-labelledby="dropdown01">
                                <li>
                                    <div className="mega-menu-content clearfix">
                                        <div className="tab">
                                            <button className="tablinks active" onClick={(e) => openCategory(e, dewasNewsCategory[0].id)}>{dewasNewsCategory[0].title}</button>
                                            {
                                                dewasNewsCategory.slice(1).map(category =>
                                                    <button key={category.id} className="tablinks" onClick={(e) => openCategory(e, category.id)}>{category.title}</button>
                                                )
                                            }

                                        </div>
                                        <div className="tab-details clearfix">
                                            <div id={dewasNewsCategory[0].id} className="tabcontent active">
                                                <MenuHeaderNewsList key={dewasNewsCategory[0].id} category={dewasNewsCategory[0]} />
                                            </div>
                                            {
                                                dewasNewsCategory.slice(1).map(category =>
                                                    <div key={category.id} id={category.id} className="tabcontent">
                                                        <MenuHeaderNewsList category={category} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        {
                            newsCategory.map(category =>
                                <Link to={`${category.linkUrl}`}>
                                    <li key={category.id} className="nav-item"
                                    //  onClick={() => history.push(`${match.url}${category.linkUrl}`)}>
                                    >
                                        <span className="nav-link color-pink-hover">{category.title}</span>
                                    </li>
                                </Link>)
                        }
                        {/* <Link to='/epaper'>
                            <li key='epaper' className="nav-item">
                                <span className="nav-link color-pink-hover">EPaper</span>
                            </li>
                        </Link> */}
                    </ul>
                </div>
            </nav>
        </div>
    </header>
)

const mapStateToProps = createStructuredSelector({
    newsCategory: selectNewsCategory,
    dewasNewsCategory: selectDewasNewsCategory
})

export default withRouter(connect(mapStateToProps)(MenuHeader))