import { all, call } from 'redux-saga/effects'
import { newsSagas } from './news/news.sagas'
import { articleSagas } from './article/article.sagas'
import { adsSagas } from './ads/ads.sagas'
import { fetchEPaperStart, fetchEPaperPageStart } from './epaper/epaper.sagas'

export default function* rootSaga() {
    yield all([
        call(newsSagas),
        call(articleSagas),
        call(adsSagas),
        call(fetchEPaperStart),
        call(fetchEPaperPageStart)
    ])
}
