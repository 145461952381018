import React from 'react'
import { connect } from 'react-redux'
import DocumentMeta from 'react-document-meta';

import { fetchArticleByIDStart } from '../redux/article/article.action'
import { selectArticleById } from '../redux/article/article.selector'
import { CategoryConstant } from '../redux/category/category.reducer'
import { increaseArticleViewCounts } from '../firebase/firebase.utils'

import { selectAllAds } from '../redux/ads/ads.selector'
import { fetchAdsStart } from '../redux/ads/ads.action'




class SingleShareArticlePage extends React.Component {
    componentDidMount() {
        const { fetchArticleByIDStart, fetchAdsStart } = this.props
        fetchArticleByIDStart(this.props.match.params.collectionId);
        fetchAdsStart()
    }

    render() {
        const feed = this.props.singleArticle
        const ads = this.props.allAds

        if (feed === undefined) {
            console.log("feed is undefined")
            return ""
        }
        const meta = {
            title: feed.heading,
            description: feed.heading,
            canonical: feed.linkUrl,
            property: "og:image",
            image: feed.imageUrl,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'news,dewas,rashtriya navachar'
                },
                property: "og:image",
                image: feed.imageUrl,
            }
        };
        const styleObj = {
            width: 'inherit',
            wordBreak: 'break-all'
        };
        increaseArticleViewCounts(feed);

        var whatsappShare = `https://api.whatsapp.com/send?text=*${feed.heading}*    \n *दैनिक राष्ट्रीय नवाचार*  \n https://rashtriyanavachar.com/singleArticleShare/${feed.id}`;
        whatsappShare = whatsappShare.replace("#", " ")

        var d = new Date(feed.crd);
        var date = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()}`
        return (
            <DocumentMeta {...meta}>
                <section className="section wb">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-wrapper">
                                    <div className="blog-title-area">
                                        <span className="color-aqua"><a>{CategoryConstant(feed.category)}</a></span>
                                        <h3>{feed.heading}</h3>
                                        <div className="blog-meta big-meta">
                                            <small><a>{date}</a></small>
                                            <small><a>{feed.reporter}</a></small>
                                            {/* <small><a href="#" title=""><i className="fa fa-eye"></i> {feed.views}</a></small> */}
                                        </div>
                                        <div className="post-sharing">
                                            {/* <FacebookProvider appId="1919321751680391">
                                                <ShareButton href="https://www.facebook.com/pratik.vishwakarma.568">
                                                    Share
                </ShareButton>
                                            </FacebookProvider>
                                            <FacebookShareButton url='' title='hello' />
                                            <FacebookShareCount url='https://www.facebook.com/pratik.vishwakarma.568' /> */}
                                            <ul className="list-inline">
                                                <li><a href={whatsappShare} className="fb-button btn btn-whatsapp">
                                                    <i className="fa fa-whatsapp"></i>
                                                    <span className="down-mobile">Share on Whatsapp</span>
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="single-post-media">
                                        <img src={feed.imageUrl} alt="" className="img-fluid" />
                                    </div>

                                    <div className="blog-content">
                                        <div className="pp">
                                            {/* <div>{feed.content}</div> */}

                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: feed.content
                                                }}
                                                style={styleObj}
                                            />
                                        </div>
                                    </div>
                                    <hr className="invis1" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                <div className="sidebar">
                                    <div className="widget">
                                        <h2 className="widget-title">Advertising</h2>
                                        {
                                            ads ?
                                                ads.map(ad =>
                                                    <div className="banner-spot clearfix">
                                                        <div className="banner-img">
                                                            <img src={ad.imageUrl} alt="" className="img-fluid" />
                                                        </div>
                                                        <hr className="invis1" />
                                                    </div>
                                                ) :
                                                <h5></h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </DocumentMeta>
        )
    }
}

const mapStateToProps = (state) => ({
    singleArticle: selectArticleById(state),
    allAds: selectAllAds(state)
})

export const mapDispatchToProp = dispatch => ({
    fetchArticleByIDStart: (id) => dispatch(fetchArticleByIDStart(id)),
    fetchAdsStart: (id) => dispatch(fetchAdsStart(id)),
})

export default connect(mapStateToProps, mapDispatchToProp)(SingleShareArticlePage)
