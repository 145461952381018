import React from 'react'

import { connect } from 'react-redux'
import { selectCategoryNews } from '../../redux/news/news.selector'

import { SmallNewsItem } from '../../components/single-news/small-news-item.component'


const SmallNewsList = ({newsCategory }) => {
    return (
        <div className="widget">
            <h2 className="widget-title">Recent Posts</h2>
            <div className="blog-list-widget">
                <div className="list-group">
                    {
                        newsCategory ?
                            newsCategory.filter((item, idx) => idx < 5).map(feed =>
                                <SmallNewsItem key={feed.id} feed={feed} />
                            ) :
                            <h5>No data found</h5>
                    }
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    newsCategory: selectCategoryNews(ownProps.category)(state)
})

export default connect(mapStateToProps)(SmallNewsList)