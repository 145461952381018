import React from 'react'

import { connect } from 'react-redux'
import { selectArticleByCategory } from '../../../redux/article/article.selector'

import { ArticleItem } from '../../article-item/article-item.component'


const HomeArticleList = ({ category, articles }) => (
    <div className="blog-list clearfix">
        <div className="section-title">
            <h3 className="color-green"><a>{category.title}</a></h3>
            {
                articles ?
                    articles.filter((item, idx) => idx < 20).map(feed =>
                        <ArticleItem feed={feed} />
                    ) :
                    <h5>No data found</h5>
            }
        </div>
    </div>
)

const mapStateToProps = (state, ownProps) => ({
    articles: selectArticleByCategory(ownProps.category.category)(state)
})

export default connect(mapStateToProps)(HomeArticleList)