import React from 'react'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router-dom'

import { fetchEPaperStart } from '../redux/epaper/epaper.action'
import { selectEPaperCover } from '../redux/epaper/epaper.selector'

import EPaperList from '../components/epaper/epaper-list.component'
import { connect } from 'react-redux'

class EPaperPage extends React.Component {

    componentDidMount() {
        const { fetchEPaperStart } = this.props
        fetchEPaperStart();
    }

    render() {
        const { ePaperCover } = this.props
        return (
            <section className="section wb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <div class="page-wrapper">
                                <h2>EPaper</h2>
                                <div class="blog-grid-system">
                                    <div class="row">
                                        <EPaperList ePaper={ePaperCover} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="sidebar">
                                {/* <SmallNewsList category={category} /> */}
                                <div className="widget">
                                    <h2 className="widget-title">Advertising</h2>
                                    <div className="banner-spot clearfix">
                                        <div className="banner-img">
                                            {/* <img src={feed.imageUrl} alt="" className="img-fluid" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    ePaperCover: selectEPaperCover()
})

const mapDispatchToProp = dispatch => ({
    fetchEPaperStart: (category) => dispatch(fetchEPaperStart())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProp)(EPaperPage))