import { ArticleActionTypes } from './article.types'

const defaultImageUrl = 'https://firebasestorage.googleapis.com/v0/b/rashtriyanavachar-2020.appspot.com/o/defaultImage%2Fdefault_image_placeholder.png?alt=media&token=63298486-e47f-4975-9a0d-d7d9e37d2cf3'
// const defaultImageUrl = 'https://i.ibb.co/px2tCc3/jackets.png'
const demoData = [
    {
        heading: 'Loading ...',
        imageUrl: defaultImageUrl,
        id: 'dewasCrime1',
        source:'',
        views: 0
    },
    {
        heading: 'Loading ...',
        imageUrl: defaultImageUrl,
        id: 'dewasCrime2',
        source:'',
        views: 0
    },
    {
        heading: 'Loading News...',
        imageUrl: defaultImageUrl,
        id: 'dewasCrime3',
        source:'',
        views: 0
    }
]

const INITIAL_STATE = {
    isFetching: false,
    errorMessage: undefined,
    singleArticle: undefined,
    isLodedAt: 0,
    articles: {
        readersCorner: JSON.parse(localStorage.getItem("readersCorner")) || demoData,
        ladiesCorner: JSON.parse(localStorage.getItem("ladiesCorner")) || demoData,
        editorsCorner: JSON.parse(localStorage.getItem("editorsCorner")) || demoData
    }
};

const ArticleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ArticleActionTypes.FETCH_ARTICLE_BY_CATEGORY_START:
            return {
                ...state,
                isFetching: true
            }
        case ArticleActionTypes.FETCH_ARTICLE_BY_CATEGORY_SUCCESS:
            {
                localStorage.setItem(action.category, JSON.stringify(action.payload) || demoData)
                return {
                    ...state,
                    isFetching: false,
                    errorMessage: null,
                    isLodedAt: Date.now(),
                    articles: {
                        ...state.articles,
                        [action.category]: action.payload
                    }
                }
            }
        case ArticleActionTypes.FETCH_ARTICLE_BY_ID_SUCCESS:
            {
                return {
                    ...state,
                    isFetching: false,
                    errorMessage: null,
                    singleArticle: action.payload
                }
            }
        case ArticleActionTypes.FETCH_ARTICLE_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        default: return state
    }
}

export default ArticleReducer