import { takeEvery, call, put, all } from 'redux-saga/effects'
import { firestore, convertAdsToObject } from '../../firebase/firebase.utils'

import { AdsActionTypes } from './ads.types'

import { fetchAdsSuccess, fetchAdsFailure } from './ads.action'

export function* fetchAdsStartAsync(action) {
    try {

        const collectionRef = firestore.collection('ads')
            .where("active", "==", true)
            .orderBy("crd", "desc")
            .limit(6)

        var snapshot = yield collectionRef.get()
        const collectionsMap = yield call(convertAdsToObject, snapshot)
        yield put(fetchAdsSuccess(collectionsMap))
    }
    catch (error) {
        yield put(fetchAdsFailure(error.message))
    }
}


export function* fetchAdsStart() {
    yield takeEvery(
        AdsActionTypes.FETCH_ADS_START,
        fetchAdsStartAsync
    )
}

export function* adsSagas() {
    yield all([
        call(fetchAdsStart)
    ])
}