import { EPaperActionTypes } from './epaper.types'

const INITIAL_STATE = {
    isFetching: false,
    errorMessage: undefined,
    ePaper: {
        epaperCover: [
            {
                heading: 'Loading Cover...',
                coverUrl: '',
                id: '1',
                category: 'dewas',
                views: 12,
                linkUrl: '/singleEpaper/1'
            },
            {
                heading: 'Loading Cover...',
                imageUrl: '',
                id: 2,
                category: 'ujjain',
                views: 12, linkUrl: '/singleEpaper/2'
            },
            {
                heading: 'Loading Cover...',
                imageUrl: '',
                id: 3,
                category: 'dewasRajneeti',
                views: 12, linkUrl: '/singleEpaper/3'
            },
        ],
        page: [
            {
                page: 'Page 1',
                imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
                id: 'page1',
                epaperId: 'epaper1',
                linkUrl: '/single/'
            },
            {
                page: 'Page 1',
                imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
                id: 'page2',
                epaperId: 'epaper1',
                linkUrl: '/single/'
            },
            {
                page: 'Page 1',
                imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
                id: 'page3',
                epaperId: 'epaper1',
                linkUrl: '/single/'
            }
        ]
    }
};

const EPaperReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EPaperActionTypes.FETCH_EPAPER_START:
            return {
                ...state,
                isFetching: true
            }
        case EPaperActionTypes.FETCH_EPAPER_SUCCESS:
            {
                return {
                    ...state,
                    isFetching: false,
                    ePaper: {
                        ...state.epaper,
                        epaperCover: action.payload
                    }
                }
            }
        case EPaperActionTypes.FETCH_EPAPER_SINGLE_SUCCESS:
            {
                return {
                    ...state,
                    isFetching: false,
                    ePaper: {
                        ...state.epaper,
                        page: action.payload
                    }
                }
            }
        case EPaperActionTypes.FETCH_EPAPER_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        case EPaperActionTypes.FETCH_EPAPER_SINGLE_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        default: return state
    }
}

export default EPaperReducer