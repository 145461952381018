import { takeEvery, takeLatest, call, put, all } from 'redux-saga/effects'
import { firestore, convertArticleToObject, convertSingleArticleDataToObject } from '../../firebase/firebase.utils'

import { ArticleActionTypes } from './article.types'

import { fetchArticleSuccessByCategory, fetchArticleFailure, fetchArticleSuccessById } from './article.action'

export function* fetchArticleByCategoryStartAsync(action) {
    try {
        const collectionRefCat = firestore.collection('article')
            .where("active", "==", true)
            .where("category", "==", action.category)
            .orderBy("crd", "desc")
            .limit(action.limit)

        const collectionRef = firestore.collection('article')
            .where("active", "==", true)
            .orderBy("crd", "desc")
            .limit(10)


        var snapshot;
        if (action.category.length === 0) {
            snapshot = yield collectionRef.get()
        } else {
            snapshot = yield collectionRefCat.get()
        }
        if (action.category.length === 0) {
            yield call(convertToMap, snapshot, "ladiesCorner")
            yield call(convertToMap, snapshot, "readersCorner")
        } else {
            const collectionsMap = yield call(convertArticleToObject, snapshot, action.category)
            yield put(fetchArticleSuccessByCategory(collectionsMap, action.category))
        }
    }
    catch (error) {
        yield put(fetchArticleFailure(error.message))
    }
}

function* convertToMap(snapshot, category) {
    const collectionsMap = yield call(convertArticleToObject, snapshot, category)
    yield put(fetchArticleSuccessByCategory(collectionsMap, category))
}

export function* fetchArticleByCategoryStart() {
    yield takeEvery(
        ArticleActionTypes.FETCH_ARTICLE_BY_CATEGORY_START,
        fetchArticleByCategoryStartAsync
    )
}


export function* fetchSingleArticleByIdAsync(action) {
    try {
        const collectionRef = firestore.collection('article')
            .doc(action.id)
        const snapshot = yield collectionRef.get()
        const article = yield call(convertSingleArticleDataToObject, snapshot)
        yield put(fetchArticleSuccessById(article))
    }
    catch (error) {
        yield put(fetchArticleFailure(error.message))
    }
}

export function* fetchArticleByIdStart() {
    yield takeLatest(
        ArticleActionTypes.FETCH_ARTICLE_BY_ID_START,
        fetchSingleArticleByIdAsync
    )
}


export function* articleSagas() {
    yield all([
        call(fetchArticleByCategoryStart),
        call(fetchArticleByIdStart)
    ])
}